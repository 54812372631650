import ResumeData from "../../../utils/resumeData";
import React from "react";
import { Typography } from "@material-ui/core";

import CustomTimeline, { CustomTimelineSeparator } from "../timeline/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineContent from "@material-ui/lab/TimelineContent";

import CustomButton from "../button/Button";

import PersonIcon from "@mui/icons-material/Person";
import GetAppIcon from "@mui/icons-material/GetApp";

import "./Profile.css";
import resumePDF from "../../../utils/EvanBlankenheimResume.pdf";
import resumeData from "../../../utils/resumeData";

const CustomTimelineItem = ({ title, text, link }) => (
  <TimelineItem>
    <CustomTimelineSeparator />
    <TimelineContent className="timeline-content">
      <Typography className="timelineItem-text">
        <span>{title}: </span>{" "}
        <a href={link} target="_blank" rel="noreferrer">
          {text}
        </a>
      </Typography>
    </TimelineContent>
  </TimelineItem>
);

const Profile = () => {
  return (
    <div className="profile container-shadow">
      <div className="profile-name">
        <Typography className="name">{ResumeData.name}</Typography>
        <Typography className="title">{ResumeData.title}</Typography>
      </div>

      <figure className="profile-image">
        <img
          src={require("../../../images/profilePhoto.jpg")}
          alt={ResumeData.name}
        />
      </figure>

      <div className="profile-information">
        <CustomTimeline icon={<PersonIcon />}>
          <CustomTimelineItem title="Name" text={resumeData.name} />
          <CustomTimelineItem title="Title" text={resumeData.title} />
          <CustomTimelineItem
            title="Email"
            text={resumeData.email}
            link={"mailto:evblankenheim@gmail.com"}
          />

          {Object.keys(resumeData.socials).map((key) => (
            <CustomTimelineItem
              title={key}
              text={resumeData.socials[key].text}
              link={resumeData.socials[key].link}
            />
          ))}
        </CustomTimeline>

        <div className="button-container">
          <CustomButton text={"Download resume"} icon={<GetAppIcon />} href={resumePDF} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
