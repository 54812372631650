import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import WebIcon from "@mui/icons-material/Web";
import SoftwareIcon from "@mui/icons-material/Terminal";
import IllustratorIcon from "@mui/icons-material/Draw";
import PlayIcon from "@mui/icons-material/PlayCircle";
import Pokemon from "../components/fun/pokemon/Pokemon";
import Game from "../components/fun/game/Game";

var resumeData = {
  name: "Evan Blankenheim",
  title: "Software Engineer",
  email: "evblankenheim@gmail.com",

  socials: {
    LinkedIn: {
      link: "https://www.linkedin.com/in/evanblankenheim/",
      text: "evanblankenheim",
      icon: <LinkedInIcon />,
    },
    GitHub: {
      link: "https://github.com/eblankenheim",
      text: "eblankenheim",
      icon: <GitHubIcon />,
    },
  },

  about:
    "Passionate Software Engineer with 5+ years of experience, dedicated to developing using the newest tech in both work and personal projects. Skilled at creating innovative solutions, I thrive in a collaborative environment, applying my skills to design, code, and test cutting-edge applications within expansive microservice systems. \n\nBeyond work, I extend my coding skills to leisure activities, often delving into personal projects and assisting friends and family with their coding needs. This not only underscores my commitment to the craft but also showcases my ability to leverage the latest technology for creative and practical solutions in various contexts. Excellent communication and problem-solving skills, coupled with expertise in debugging and troubleshooting, allow me to navigate and overcome complex technical challenges seamlessly.",

  workExperience: [
    {
      title: "Northwestern Mutual",
      dateRange: "Software Engineer | 2022 - Present",
      description:
        "I played a pivotal role in enhancing our service desk management system by introducing a range of new features. One notable achievement was designing, deploying, and scheduling over a million notifications. I developed logic that allowed us to schedule notifications with dynamic content, promoting reusability and maintaining functionality through rigorous testing in non-production environments. This initiative ensured stable and accurate communication across the entire company, benefitting our clients, field representatives, and home office employees. ",
    },
    {
      title: "Uline",
      dateRange: "Quality Assurance Analyst | 2021 - 2022",
      description:
        "I took care of various testing processes, including integration testing, functional system testing, regression testing, end-to-end component testing, and stress testing. I also had the job of creating test cases, setting acceptance criteria, and estimating the effort required, all while keeping an eye on our capacity and making sure we followed agile standards.",
    },
    {
      title: "College Pro",
      dateRange: "Job Site Manager | 2019 - 2020",
      description:
        "I oversaw various business operations, ensuring that we consistently made accurate work estimates by taking into account the team's capacity and skill set. We successfully met all service requirements, making sure to stay within our budget and time constraints. My responsibilities extended to managing all on-site operations, which included strategic planning, executing services, communicating with customers, and efficiently handling resources throughout every phase of our process.",
    },
  ],
  education: [
    {
      title: "Genesis10 Dev10 Bootcamp",
      dateRange: "Full Stack Java Developer | 2020 - 2021",
      description:
        "Chosen as one of the fortunate few from a fiercely competitive applicant pool to participate in the highly regarded Dev10 Program, where only 5% of applicants earned a spot. Here, I underwent an immersive training experience in Full Stack Java development, equipping me with a deep and diverse skill set in software engineering.",
    },
    {
      title: "University of Wisconsin - Eau Claire",
      dateRange: "BBA - Information Systems | 2016 - 2020",
      description:
        "Former Leadership Community Member, actively engaged in addressing campus issues by identifying challenges and implementing effective solutions. Dedicated volunteer at local food pantries, supervising fellow students on various leadership projects, and offering tutoring services in Computer Science (CS) and Information Systems (IS) courses.",
    },
  ],
  services: [
    {
      title: "Software Engineering",
      description:
        "I have accumulated over four years of experience in coding, and my enthusiasm for it remains unwavering. While my primary focus lies in front-end application development, I also possess a level of proficiency in back-end development.",
      icon: <SoftwareIcon />,
    },
    {
      title: "Illustrator",
      description:
        "Over a decade ago, I embarked on my journey in cinematography, and my passion for the art form has only deepened since. My initial fascination was rooted in the exploration of its artistic essence and fundamental principles. Over the years, I have immersed myself in various classes and projects.",
      icon: <IllustratorIcon />,
    },
    {
      title: "Web Development",
      description:
        "My exploration of web development initiated during my high school years and was subsequently nurtured through my college coursework. It has since evolved into a fulfilling endeavor, applying my knowledge to websites that cater to the needs of small business proprietors.",
      icon: <WebIcon />,
    },
  ],
  skills: [
    {
      title: "Front-end",
      description: ["React", "Ionic", "Typescript", "Javascript", "Bootstrap", "Material UI"],
    },
    {
      title: "Back-end",
      description: ["NodeJS", "NPM","Capacitor", "Java", "Express"],
    },
    {
      title: "Database",
      description: ["Supabase","Firebase", "MySQL", "Dbeaver", "TablePlus"],
    },
    {
      title: "Source Control",
      description: ["Git", "GitLab", "Scrum/Agile", "Jira", "Microsoft Azure"],
    },
    {
      title: "Programs",
      description: ["VS Code", "IntelliJ", "Insomnia", "Postman", "Kibana"],
    },
  ],

  projects: [
    {
      tag: "App",
      app: <Pokemon />,
      image: `${require("../images/apps/pokemon.JPG")}`,
      title: "Pokemon Generator",
      description:
        "Pursued an engaging, self-initiated project aimed at deepening my proficiency with the Axios library while exploring the functionality of the Pokedex API. This involved skillfully crafting Axios-based GET requests to access the API's endpoint. Notably, I honed my ability to extract essential data, including names and image URLs, by dynamically supplying a randomized integer index within the request payload.",
      caption: "Generate a random pokemon",
    },
    {
      tag: "Website",
      image: `${require("../images/web/amalias/amalias_restaurant_website_before.jpg")}`,
      images: [
        `${require("../images/web/amalias/amalias_restaurant_website.jpg")}`,
        `${require("../images/web/amalias/amalias_restaurant_website_before.jpg")}`,
      ],
      title: "Amalia's Family Restaurant",
      description:
        "Dedicatedly designed a website to overhaul the online presence of a local business, which was previously plagued by a subpar website. This endeavor served as an opportunity to hone my HTML and CSS skills and marked a substantial improvement for the business. The entire website, comprising code and content, was meticulously crafted from the ground up. This hands-on experience enriched my understanding of whitespace management, content structure, layout intricacies, and seamless flow in web design.",
      caption: "Family restaurant website project",
      links: [
        {
          link: "https://amaliasfamilyrestaurant.com/",
          icon: <PlayIcon />,
          text: "Open",
        },
        {
          link: "https://github.com/anonymousUser391/amaliasfamilyRestaurant/",
          icon: <GitHubIcon />,
          text: "View Code",
        },
      ],
    },
    {
      tag: "App",
      app: <Game />,
      image: `${require("../images/apps/tictactoe.JPG")}`,
      title: "Tic-Tac-Toe App",
      description:
        "This application is the outcome of a comprehensive tutorial on fundamental React concepts. Throughout the tutorial, I gained proficiency in crafting handler functions, utilizing useState, working with arrays, creating custom functions, and displaying a strong command of JSX.",
      caption: "Basic React App",
    },
    {
      tag: "Website",
      image: `${require("../images/web/xtreme/xtreme_midwest_website_before.jpg")}`,
      images: [
        `${require("../images/web/xtreme/xtreme_midwest_website.jpg")}`,
        `${require("../images/web/xtreme/xtreme_midwest_website_before.jpg")}`,
      ],
      title: "Xtreme Enterprises",
      description:
        "I artfully crafted the design, content, and layout of this website, taking ownership of every aspect. I encountered various challenges related to color schemes, functionality, and brand alignment, which I successfully addressed. Ultimately, my client conveyed gratitude for the valuable assistance provided and expressed satisfaction with the final outcome.",
      caption: "E-commerce website project",
      links: [
        {
          link: "https://xtrememidwest.com/",
          icon: <PlayIcon />,
          text: "Open",
        },
        {
          link: "https://github.com/xtrememidwest/xtrememidwest",
          icon: <GitHubIcon />,
          text: "View Code",
        },
      ],
    },
  ],
};

export default resumeData;
